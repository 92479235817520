module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"./content/assets/myLogo.jpeg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-baidu-tongji/gatsby-browser.js'),
      options: {"plugins":[],"siteid":"7d972948fda682a44dfb490ed2060bb6","head":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
